import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './Home/Home';
import Header from './Header/Header';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <>
    <Header/>
    <Home/>
    </>
  </React.StrictMode>
);

