import React from 'react'
import './Header.css'
import Logo from '../Images/impressionLogo.jpg'
import Undi from '../Images/undi.png'


function Header() {
  return (
    <>
      <header>
        <img src={Undi} alt="Himali" className='undi' />
        <div className="logo">
          <img src={Logo} alt="Himali" />
        </div>
        <img src={Undi} alt="Himali" className='undi' />
      </header>
    </>
  )
}

export default Header;
