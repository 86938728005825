import React from 'react'
import './Home.css'
import bgVideo from '../Images/bgPhotoVideo.mp4'



function Home() {
  return (
    <>
      <main className='mainContainer'>
        <video autoPlay muted loop id="video-bg">
          <source src={bgVideo} type="video/mp4"></source>
          Your browser does not support the video tag.
        </video>
        <div className="container">
          <h2>Impression Studio</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, blanditiis!
          </p>
          <div className="gallery">
            <div className="box row-2" id='box1'>Box1</div>
            <div className="box col-2" id='box2'>Box2</div>
            <div className="box" id='box3'>Box3</div>
            <div className="box row-2" id='box4'>Box4</div>
            <div className="box col-2" id='box5'>Box5</div>
            <div className="box" id='box6'>Box6</div>
            <div className="box col-2" id='box7'>Box7</div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Home
